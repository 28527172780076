import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./homepage.scss";
import { useCoinContextData } from "../../context/CoinContext";
import useWindowDimensions from "../../utils/WindowSize";
import { useStore } from "../../components/store/store.ts";
import MobMenu from "../../components/mobMenu/mobMenu";
import Navbar from "../../components/Navbar/index.jsx";

//images
import discoverIcon from "../../static/images/homepage/discoverIcon.svg";
import refineIcon from "../../static/images/homepage/refineIcon.svg";
import mentorIcon from "../../static/images/homepage/mentorIcon.svg";
import analyzeIcon from "../../static/images/homepage/analyzeIcon.svg";
import adjustIcon from "../../static/images/homepage/adjustIcon.svg";
import accomplishIcon from "../../static/images/homepage/accomplishIcon.svg";
import heroimg from "../../static/images/homepage/heroImg.svg";
import Footer from "../../components/Footer/index.jsx";

const HomePage = () => {
  const navigate = useNavigate();
  const { setSchoolSearch } = useCoinContextData();
  const { width } = useWindowDimensions();
  const { mobMenuOpen } = useStore();

  const hiwData = [
    {
      id: 1,
      name: "Compliance",
      icon: discoverIcon,
    },
    {
      id: 2,
      name: "Contracts",
      icon: refineIcon,
    },
    {
      id: 3,
      name: "Counsel",
      icon: mentorIcon,
    },
    {
      id: 4,
      name: "Monitor",
      icon: analyzeIcon,
    },
    {
      id: 5,
      name: "Strategy",
      icon: adjustIcon,
    },
    {
      id: 6,
      name: "Share",
      icon: accomplishIcon,
    },
  ];

  return (
    <div className="homepage">
      <Navbar />
      {width > 768 ? (
        <div className="homepage-content">
          <div className="hero-content">
            <div className="hero-text-div">
              <div className="hero-text1">Focus On Your Business</div>
              <div className="hero-text2">Leave The Legalities To Us</div>
              <div className="hero-text3">
                Legal Functions Absorb Uneccessary Time And Capital From
                Businesses. Not Anymore! The InstaLegal App Is Like Having Your
                Very Own Legal Department Without The Cost.
              </div>
            </div>
            <div className="hero-img-div">
              <img src={heroimg} alt="" />
            </div>
          </div>
          <div className="input-box-container">
            <div className="input-box1">
              <input
                type="text"
                placeholder="What service are you looking for?"
                onChange={(e) => {
                  setSchoolSearch(e.target.value);
                }}
              />
            </div>
            <div
              className="createPath-btn"
              onClick={() => {
                navigate("/contact");
              }}
            >
              Find Options
            </div>
          </div>

          <div className="hiw-container">
            <div className="hiw-text-div">
              <div className="hiw-text1">One App. No Hassle</div>
              <div className="hiw-text2">
                For all you’re startup compliance needs
              </div>
            </div>
            <div className="hiw-options">
              {hiwData.map((e, i) => {
                return (
                  <div className="each-hiw-option" key={e.id}>
                    <div className="img-border">
                      <img src={e.icon} alt="" />
                    </div>
                    <div className="each-hiw-option-name">{e.name}</div>
                  </div>
                );
              })}
              <div className="centre-line"></div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <>
          {!mobMenuOpen ? (
            <div className="homepage-content">
              <div className="hero-content-mob">
                <div className="hero-text-div-mob">
                  <div className="hero-text1-mob">Focus On Your Business</div>
                  <div className="hero-text2-mob">
                    Leave The Legalities To Us
                  </div>
                  <div className="hero-text3-mob">
                    Legal Functions Absorb Uneccessary Time And Capital From
                    Businesses. Not Anymore! The InstaLegal App Is Like Having
                    Your Very Own Legal Department Without The Cost.
                  </div>
                </div>
                <div className="hero-img-div-mob">
                  <img src={heroimg} alt="" />
                </div>
              </div>
              <div className="input-box-container-mob">
                <div className="input-box1-mob">
                  <input type="text" placeholder="Search a service" />
                  <div
                    className="createPath-btn-mob"
                    onClick={() => {
                      navigate("/maps");
                    }}
                  >
                    Go
                  </div>
                </div>
              </div>
              <div className="hiw-container">
                <div className="hiw-text-div">
                  <div className="hiw-text2-mob">How It Works</div>
                </div>
                <div className="hiw-options">
                  {hiwData.map((e, i) => {
                    return (
                      <div className="each-hiw-option" key={e.id}>
                        <div className="img-border">
                          <img src={e.icon} alt="" />
                        </div>
                        <div className="each-hiw-option-name">{e.name}</div>
                      </div>
                    );
                  })}
                  <div className="centre-line"></div>
                </div>
              </div>
              <Footer />
            </div>
          ) : (
            <MobMenu />
          )}
        </>
      )}
    </div>
  );
};

export default HomePage;
