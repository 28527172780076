import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./contact.scss";
import { useCoinContextData } from "../../context/CoinContext.js";
import useWindowDimensions from "../../utils/WindowSize.js";
import { useStore } from "../../components/store/store.ts";
import MobMenu from "../../components/mobMenu/mobMenu.jsx";
import Navbar from "../../components/Navbar/index.jsx";

//images
import discoverIcon from "../../static/images/homepage/discoverIcon.svg";
import refineIcon from "../../static/images/homepage/refineIcon.svg";
import mentorIcon from "../../static/images/homepage/mentorIcon.svg";
import analyzeIcon from "../../static/images/homepage/analyzeIcon.svg";
import adjustIcon from "../../static/images/homepage/adjustIcon.svg";
import accomplishIcon from "../../static/images/homepage/accomplishIcon.svg";
import heroimg from "./contact_hero.svg";
import officeImg from "./office.svg";
import phoneImg from "./phone.svg";
import emailImg from "./email.svg";
import Footer from "../../components/Footer/index.jsx";

const ContactPage = () => {
  const navigate = useNavigate();
  const { setSchoolSearch } = useCoinContextData();
  const { width } = useWindowDimensions();
  const { mobMenuOpen } = useStore();

  const hiwData = [
    {
      id: 1,
      name: "Compliance",
      icon: discoverIcon,
    },
    {
      id: 2,
      name: "Contracts",
      icon: refineIcon,
    },
    {
      id: 3,
      name: "Counsel",
      icon: mentorIcon,
    },
    {
      id: 4,
      name: "Monitor",
      icon: analyzeIcon,
    },
    {
      id: 5,
      name: "Strategy",
      icon: adjustIcon,
    },
    {
      id: 6,
      name: "Share",
      icon: accomplishIcon,
    },
  ];

  return (
    <div className="homepage1">
      <Navbar />
      {width > 768 ? (
        <div className="homepage-content">
          <div className="hero-content">
            <div className="hero-img-div" style={{}}>
              <img src={heroimg} alt="" />
            </div>
            <div className="hero-text-div">
              <div className="hero-text1">We are here to help!</div>
              {/* <div className="hero-text2">Leave The Legalities To Us</div> */}
              <div className="hero-text3">
                Visit our help center for assistance, and create a ticket if you
                need further help.
              </div>
            </div>
          </div>
          <div className="card-container">
            <div>
              <div className="cardLabel">Offices</div>
              <div className="cardwrapper">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={officeImg} alt="" />
                  <div className="cardTitle">
                    <span>Hyderabad</span> (Registered Office){" "}
                  </div>
                </div>
                <div className="cardSubtitle">
                  A 507, Patel Golf Links, Yapral, Secunderabad, Hyderabad,
                  Telangana, India - 500087
                </div>
              </div>
            </div>
            <div>
              <div className="cardLabel">Schedule A Callback</div>
              <div className="cardwrapper">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={phoneImg} alt="" />
                  <div className="cardTitle">+91 74167 08117</div>
                </div>
                <div className="cardBadge">
                  Call us between: Monday - Saturday (10 AM - 7 PM)
                </div>
              </div>
              <br />
              <div className="cardLabel">Email</div>
              <div className="cardwrapper">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={emailImg} alt="" />
                  <div className="cardTitle">support@legally.app</div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <>
          {!mobMenuOpen ? (
            <div className="homepage-content">
              <div className="mob-hero">
                <div>We are here to help!</div>
                <div>
                  Visit our help center for assistance, and create a ticket if
                  you need further help.
                  <img src={heroimg} alt="" />
                </div>
              </div>
              {/* <div className="hero-img-div-mob1"></div> */}
              <div className="card-container">
                <div>
                  <div className="cardLabel">Offices</div>
                  <div className="cardwrapper">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={officeImg} alt="" />
                      <div className="cardTitle">
                        <span>Hyderabad</span> (Registered Office){" "}
                      </div>
                    </div>
                    <div className="cardSubtitle">
                      A 507, Patel Golf Links, Yapral, Secunderabad, Hyderabad,
                      Telangana, India - 500087
                    </div>
                  </div>
                </div>
                <div>
                  <div className="cardLabel">Schedule A Callback</div>
                  <div className="cardwrapper">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={phoneImg} alt="" />
                      <div className="cardTitle">+91 74167 08117</div>
                    </div>
                    <div className="cardBadge">
                      Call us between: Monday - Saturday (10 AM - 7 PM)
                    </div>
                  </div>
                  <br />
                  <div className="cardLabel">Email</div>
                  <div className="cardwrapper">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={emailImg} alt="" />
                      <div className="cardTitle">support@legally.app</div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          ) : (
            <MobMenu />
          )}
        </>
      )}
    </div>
  );
};

export default ContactPage;
