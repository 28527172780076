import React from "react";
import Navbar from "../components/Navbar";

const Shipping = () => {
  return (
    <>
      <Navbar />
      <div
        style={{ padding: "20px 7%", overflowY: "scroll", lineHeight: "30px" }}
      >
        <h1>
          <strong>Shipping and Delivery</strong>
        </h1>
        <p>
          At Starfish Accelerator Partners Prviate Limited, we understand the
          importance of timely and secure shipping for our customers in the
          legal industry. Below, we outline our shipping and delivery policies
          to ensure a seamless experience.
        </p>

        <h2>
          <strong>1. Order Processing Time</strong>
        </h2>
        <p>
          Upon receipt of your order, our dedicated team works diligently to
          process it efficiently. Orders are typically processed within 1-3
          business days, excluding weekends and holidays. Please note that
          processing times may vary during peak seasons or due to unforeseen
          circumstances, but we strive to fulfill orders promptly.
        </p>

        <h2>
          <strong>2. Shipping Methods</strong>
        </h2>
        <p>
          We offer various shipping methods to accommodate your preferences and
          delivery needs. These may include standard ground shipping, expedited
          shipping, or specialized shipping services for bulk or sensitive
          orders. During the checkout process, you will have the option to
          select the shipping method that best suits your requirements.
        </p>

        <h2>
          <strong>3. Shipping Rates</strong>
        </h2>
        <p>
          Shipping rates are calculated based on several factors, including the
          weight and dimensions of the package, the selected shipping method,
          and the destination address. Our website provides transparent shipping
          rate information, allowing you to review and select the most
          cost-effective option for your order.
        </p>

        <h2>
          <strong>4. Delivery Timeframes</strong>
        </h2>
        <p>
          Once your order has been processed and shipped, delivery timeframes
          depend on the shipping method chosen and the destination location.
          While we strive to provide accurate estimated delivery dates, please
          note that unforeseen delays may occur during transit or due to
          external factors beyond our control. We recommend tracking your order
          using the provided tracking information for real-time updates on its
          status and expected delivery timeframe.
        </p>

        <h2>
          <strong>5. International Shipping</strong>
        </h2>
        <p>
          For international orders, additional customs clearance procedures and
          import duties may apply, depending on the destination country's
          regulations. Please be aware of any potential customs fees or taxes
          that may be imposed upon delivery, as these are the responsibility of
          the recipient. We aim to facilitate smooth customs clearance processes
          to minimize any delays or complications.
        </p>

        <h2>
          <strong>6. Shipping Partners</strong>
        </h2>
        <p>
          We collaborate with reputable shipping carriers and logistics partners
          to ensure reliable and secure delivery of your orders. By leveraging
          trusted networks and industry-leading practices, we prioritize the
          safety and integrity of your shipments throughout the shipping
          process.
        </p>

        <h2>
          <strong>7. Shipping Restrictions</strong>
        </h2>
        <p>
          Some products may be subject to shipping restrictions or regulations
          based on their nature or destination. Before placing your order,
          please review any applicable shipping restrictions to ensure
          compliance with legal requirements and avoid any complications during
          the shipping process.
        </p>

        <h2>
          <strong>8. Customer Support</strong>
        </h2>
        <p>
          Should you have any questions or concerns regarding shipping and
          delivery, our dedicated customer support team is available to assist
          you. Whether you need assistance with tracking your order, resolving
          delivery issues, or clarifying shipping-related inquiries, we are here
          to provide prompt and personalized support to ensure your
          satisfaction.
        </p>

        <p>
          At <strong>Starfish Accelerator Partners Prviate Limited</strong>, we
          are committed to delivering a superior customer experience by
          providing reliable shipping and delivery services tailored to your
          needs. Thank you for choosing us as your trusted partner in the legal
          industry.
        </p>
      </div>
    </>
  );
};

export default Shipping;
