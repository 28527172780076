import React from "react";
import { useNavigate } from "react-router-dom";
import { useCoinContextData } from "../../context/CoinContext.js";
import { useStore } from "../store/store.ts";
import "./footer.scss";

//images
import logo from "../../static/images/logo.svg";
import hamIcon from "../../static/images/icons/hamIcon.svg";
import cross from "../../static/images/nav/cross.svg";
import footerLogo from "./footer_logo.svg";

const Footer = () => {
  const navigate = useNavigate();
  const { preLoginMenu, setPreLoginMenu, setSchoolSearch } =
    useCoinContextData();
  const { mobMenuOpen, setselectedNav, setmobMenuOpen } = useStore();

  return (
    <>
      <div className="footer">
        <div className="firstCol">
          <img src={footerLogo} alt="" />
          <div>
            <span>Email:</span> support@legally.app
          </div>
          <div>
            <span>Phone:</span> +91 74167 08117
          </div>

          <div style={{ width: "250px", lineHeight: "32px" }}>
            <span style={{ textDecoration: "underline" }}>Address:</span>
            <br />
            <div style={{ fontWeight: 400, marginTop: "10px" }}>
              A 507, Patel Golf Links, Yapral, Secunderabad, Hyderabad,
              Telangana, India - 500087
            </div>
          </div>
        </div>
        <div className="secondCol">
          <div onClick={(e) => navigate("/privacy")}>Privacy Policy</div>
          <div onClick={(e) => navigate("/terms")}>Terms & Conditions</div>
          <div onClick={(e) => navigate("/cancellation")}>
            Cancellation & Refund
          </div>
          <div onClick={(e) => navigate("/shipping")}>Shipping & Delivery</div>
        </div>
      </div>
      <div className="footerMobile">
        <div className="firstCol">
          <img src={footerLogo} alt="" />
          <div>
            <span>Email:</span> support@legally.app
          </div>
          <div>
            <span>Phone:</span> +91 74167 08117
          </div>

          <div
            style={{
              width: "250px",
              lineHeight: "32px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={{ textDecoration: "underline" }}>Address:</span>
            <div
              style={{
                fontWeight: 400,
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              A 507, Patel Golf Links, Yapral, Secunderabad, Hyderabad,
              Telangana, India - 500087
            </div>
          </div>
        </div>
        <div className="secondCol">
          <div onClick={(e) => navigate("/privacy")}>Privacy Policy</div>
          <div onClick={(e) => navigate("/terms")}>Terms & Conditions</div>
          <div onClick={(e) => navigate("/cancellation")}>
            Cancellation & Refund
          </div>
          <div onClick={(e) => navigate("/shipping")}>Shipping & Delivery</div>
        </div>
      </div>
    </>
  );
};

export default Footer;
