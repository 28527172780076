import React from "react";
import Navbar from "../components/Navbar";

const Privacy = () => {
  return (
    <>
      <Navbar />
      <div style={{ padding: "20px 7%", overflowY: "scroll" }}>
        <h1>
          <strong>
            Privacy Policy for Starfish Accelerator Partners Prviate Limited
          </strong>
        </h1>

        <p>
          At Starfish Accelerator Partners Prviate Limited, one of our main
          priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by Starfish Accelerator Partners Prviate Limited and how we use it.
        </p>

        <p>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </p>

        <h2>
          <strong>Log Files</strong>
        </h2>

        <p>
          Starfish Accelerator Partners Prviate Limited follows a standard
          procedure of using log files. These files log visitors when they use
          app. The information collected by log files include internet protocol
          (IP) addresses, browser type, Internet Service Provider (ISP), date
          and time stamp, referring/exit pages, and possibly the number of
          clicks. These are not linked to any information that is personally
          identifiable. The purpose of the information is for analyzing trends,
          administering the app, tracking users' movement on the app, and
          gathering demographic information.
        </p>

        <h2>
          <strong>Our Advertising Partners</strong>
        </h2>

        <p>
          Some of advertisers in our app may use cookies and web beacons. Our
          advertising partners are listed below. Each of our advertising
          partners has their own Privacy Policy for their policies on user data.
          For easier access, we hyperlinked to their Privacy Policies below.
        </p>

        <ul>
          <li>
            <p>Google</p>
            <p>
              <a href="https://policies.google.com/technologies/ads">
                https://policies.google.com/technologies/ads
              </a>
            </p>
          </li>
        </ul>

        <h2>
          <strong>Privacy Policies</strong>
        </h2>

        <p>
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of Starfish Accelerator Partners Prviate Limited.
        </p>

        <p>
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Beacons that are used in their respective
          advertisements and links that appear on Starfish Accelerator Partners
          Prviate Limited. They automatically receive your IP address when this
          occurs. These technologies are used to measure the effectiveness of
          their advertising campaigns and/or to personalize the advertising
          content that you see on this app or other apps or websites.
        </p>

        <p>
          Note that Starfish Accelerator Partners Prviate Limited has no access
          to or control over these cookies that are used by third-party
          advertisers.
        </p>

        <h2>
          <strong>Third Party Privacy Policies</strong>
        </h2>

        <p>
          Starfish Accelerator Partners Prviate Limited's Privacy Policy does
          not apply to other advertisers or websites. Thus, we are advising you
          to consult the respective Privacy Policies of these third-party ad
          servers for more detailed information. It may include their practices
          and instructions about how to opt-out of certain options.
        </p>

        <h2>
          <strong>Children's Information</strong>
        </h2>

        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>

        <p>
          Starfish Accelerator Partners Prviate Limited does not knowingly
          collect any Personal Identifiable Information from children under the
          age of 13. If you think that your child provided this kind of
          information on our App, we strongly encourage you to contact us
          immediately and we will do our best efforts to promptly remove such
          information from our records.
        </p>

        <h2>
          <strong>Online Privacy Policy Only</strong>
        </h2>

        <p>
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our App with regards to the information that they
          shared and/or collect in Starfish Accelerator Partners Prviate
          Limited. This policy is not applicable to any information collected
          offline or via channels other than this app.{" "}
        </p>

        <h2>
          <strong>Consent</strong>
        </h2>

        <p>
          By using our app, you hereby consent to our Privacy Policy and agree
          to its Terms and Conditions.
        </p>
        <h2>
          <strong>
            Disclaimer for Starfish Accelerator Partners Prviate Limited
          </strong>
        </h2>

        <p>
          We are doing our best to prepare the content of this app. However,
          Starfish Accelerator Partners Prviate Limited cannot warranty the
          expressions and suggestions of the contents, as well as its accuracy.
          In addition, to the extent permitted by the law, Starfish Accelerator
          Partners Prviate Limited shall not be responsible for any losses
          and/or damages due to the usage of the information on our app.{" "}
        </p>

        <p>
          By using our app, you hereby consent to our disclaimer and agree to
          its terms.
        </p>

        <p>
          Any links contained in our app may lead to external sites are provided
          for convenience only. Any information or statements that appeared in
          these sites or app are not sponsored, endorsed, or otherwise approved
          by Starfish Accelerator Partners Prviate Limited. For these external
          sites, Starfish Accelerator Partners Prviate Limited cannot be held
          liable for the availability of, or the content located on or through
          it. Plus, any losses or damages occurred from using these contents or
          the internet generally.
        </p>
      </div>
    </>
  );
};

export default Privacy;
