import React, { useState, useEffect } from "react";
import { useCoinContextData } from "../../context/CoinContext";
import Skeleton from "react-loading-skeleton";
import "./mypaths.scss";
import axios from "axios";

// images
import dummy from "./dummy.svg";
import closepop from "../../static/images/dashboard/closepop.svg";
import lg1 from "../../static/images/login/lg1.svg";

const MyPaths = ({ search }) => {
  let userDetails = JSON.parse(localStorage.getItem("user"));
  const { mypathsMenu, setMypathsMenu } = useCoinContextData();
  const [partnerPathData, setPartnerPathData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [partnerStepsData, setPartnerStepsData] = useState([]);
  const [selectedPathId, setSelectedPathId] = useState("");
  const [pathActionEnabled, setPathActionEnabled] = useState(false);
  const [pathActionStep, setPathActionStep] = useState(1);
  const [actionLoading, setActionLoading] = useState(false);
  const [selectedStepId, setSelectedStepId] = useState("");
  const [stepActionEnabled, setStepActionEnabled] = useState(false);
  const [stepActionStep, setStepActionStep] = useState(1);
  const [editPaths, setEditPaths] = useState("default");
  const [metaDataStep, setMetaDataStep] = useState("default");
  const [selectedPath, setSelectedPath] = useState([]);
  const [newValue, setNewValue] = useState("");
  const [viewPathEnabled, setViewPathEnabled] = useState(false);
  const [viewPathLoading, setViewPathLoading] = useState(false);
  const [viewPathData, setViewPathData] = useState([]);

  const getAllPaths = () => {
    setLoading(true);
    let email = userDetails?.user?.email;
    axios
      .get(`https://careers.marketsverse.com/paths/get?email=${email}`)
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, "partnerPathData result");
        setPartnerPathData(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error in partnerPathData");
      });
  };

  const getAllSteps = () => {
    setLoading(true);
    let email = userDetails?.user?.email;
    axios
      .get(`https://careers.marketsverse.com/steps/get?email=${email}`)
      .then((response) => {
        let result = response?.data?.data;
        // console.log(result, "partnerStepsData result");
        setPartnerStepsData(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error in partnerStepsData");
      });
  };

  useEffect(() => {
    getAllPaths();
  }, []);

  useEffect(() => {
    getAllSteps();
  }, []);

  const filteredPartnerPathData = partnerPathData?.filter((entry) =>
    entry?.nameOfPath?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const filteredPartnerStepsData = partnerStepsData?.filter((entry) =>
    entry?.name?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const myPathsTimeout = () => {
    setTimeout(reload1, 2000);
  };

  function reload1() {
    getAllPaths();
    setPathActionEnabled(false);
    setPathActionStep(1);
    setSelectedPathId("");
    setEditPaths("default");
    setMetaDataStep("default");
    setSelectedPath([]);
    setNewValue("");
  }

  const myStepsTimeout = () => {
    setTimeout(reload2, 2000);
  };

  function reload2() {
    getAllSteps();
    setStepActionEnabled(false);
    setStepActionStep(1);
    setSelectedStepId("");
  }

  const deletePath = () => {
    setActionLoading(true);
    axios
      .delete(`https://careers.marketsverse.com/paths/delete/${selectedPathId}`)
      .then((response) => {
        let result = response?.data;
        // console.log(result, "deletePath result");
        if (result?.status) {
          setActionLoading(false);
          setPathActionStep(3);
          myPathsTimeout();
        }
      })
      .catch((error) => {
        console.log(error, "error in deletePath");
      });
  };

  const deleteStep = () => {
    setActionLoading(true);
    axios
      .delete(`https://careers.marketsverse.com/steps/delete/${selectedStepId}`)
      .then((response) => {
        let result = response?.data;
        // console.log(result, "deleteStep result");
        if (result?.status) {
          setActionLoading(false);
          setStepActionStep(3);
          myStepsTimeout();
        }
      })
      .catch((error) => {
        console.log(error, "error in deleteStep");
      });
  };

  const resetPathAction = () => {
    setPathActionEnabled(false);
    setPathActionStep(1);
    setSelectedPathId("");
    setEditPaths("default");
    setMetaDataStep("default");
    setSelectedPath([]);
    setNewValue("");
    setViewPathData([]);
  };

  const editMetaData = (field) => {
    setActionLoading(true);
    let obj = {
      [field]: newValue,
    };

    // console.log(obj, "obj");
    // console.log(selectedPathId, "selectedPathId");

    axios
      .put(
        `https://careers.marketsverse.com/paths/update/${selectedPathId}`,
        obj
      )
      .then((response) => {
        let result = response?.data;
        // console.log(result, "editMetaData result");
        if (result?.status) {
          setMetaDataStep("success");
          setActionLoading(false);
          myPathsTimeout();
        }
      })
      .catch((error) => {
        console.log(error, "ediMetaData error");
      });
  };

  const viewPath = (path) => {
    setViewPathLoading(true);
    axios
      .get(`https://careers.marketsverse.com/paths/get?nameOfPath=${path}`)
      .then((response) => {
        let result = response?.data?.data[0];
        // console.log(result, "viewPathData result");
        setViewPathData(result);
        setViewPathLoading(false);
      })
      .catch((error) => {
        console.log(error, "error in fetching viewPathData");
      });
  };

  return (
    <div className="mypaths">
      <div className="mypaths-menu">
        <div
          className="each-mypath-menu"
          style={{
            fontWeight: mypathsMenu === "Paths" ? "700" : "",
            background:
              mypathsMenu === "Paths" ? "rgba(241, 241, 241, 0.5)" : "",
          }}
          onClick={() => {
            setMypathsMenu("Paths");
            if(viewPathEnabled) {
              setViewPathEnabled(false);
              setViewPathData([]);
            }
          }}
        >
          Paths
        </div>
        <div
          className="each-mypath-menu"
          style={{
            fontWeight: mypathsMenu === "Steps" ? "700" : "",
            background:
              mypathsMenu === "Steps" ? "rgba(241, 241, 241, 0.5)" : "",
          }}
          onClick={() => {
            setMypathsMenu("Steps");
            if(viewPathEnabled) {
              setViewPathEnabled(false);
              setViewPathData([]);
            }
          }}
        >
          Steps
        </div>
      </div>
      <div className="mypaths-content">
        {viewPathEnabled ? (
          <div className="viewpath-container">
            <div className="viewpath-top-area">
              <div>Your Selected Path:</div>
              {viewPathLoading ? (
                <Skeleton width={150} height={30} />
              ) : (
                <div className="viewpath-bold-text">
                  {viewPathData?.length > 0
                    ? viewPathData?.destination_institution
                    : ""}
                </div>
              )}
              {viewPathLoading ? (
                <Skeleton width={500} height={20} />
              ) : (
                <div className="viewpath-des">
                  {viewPathData?.length > 0 ? viewPathData?.description : ""}
                </div>
              )}
              <div
                className="viewpath-goBack-div"
                onClick={() => {
                  setViewPathEnabled(false);
                }}
              >
                Go Back
              </div>
            </div>
            <div className="viewpath-steps-area">
              {viewPathLoading
                ? Array(6)
                    .fill("")
                    .map((e, i) => {
                      return (
                        <div
                          className="viewpath-each-j-step viewpath-relative-div"
                          key={i}
                        >
                          <div className="viewpath-each-j-img">
                            <Skeleton width={75} height={75} />
                          </div>
                          <div className="viewpath-each-j-step-text">
                            <Skeleton width={200} height={30} />
                          </div>
                          <div className="viewpath-each-j-step-text1">
                            <Skeleton width={250} height={25} />
                          </div>
                          <div className="viewpath-each-j-amount-div">
                            <div className="viewpath-each-j-amount">
                              <Skeleton width={100} height={30} />
                            </div>
                          </div>
                        </div>
                      );
                    })
                : viewPathData?.length > 0
                ? viewPathData?.StepDetails?.map((e, i) => {
                    return (
                      <div
                        className="viewpath-each-j-step viewpath-relative-div"
                        key={i}
                      >
                        <div className="viewpath-each-j-img">
                          <img src={e?.icon} alt="" />
                        </div>
                        <div className="viewpath-each-j-step-text">
                          {e?.name}
                        </div>
                        <div className="viewpath-each-j-step-text1">
                          {e?.description}
                        </div>
                        <div className="viewpath-each-j-amount-div">
                          <div className="viewpath-each-j-amount">
                            {e?.cost}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        ) : mypathsMenu === "Paths" && !viewPathEnabled ? (
          <>
            <div className="mypathsNav">
              <div className="mypaths-name-div">Name</div>
              <div className="mypaths-description-div">Description</div>
            </div>
            <div className="mypathsScroll-div">
              {loading
                ? Array(10)
                    .fill("")
                    .map((e, i) => {
                      return (
                        <div className="each-mypaths-data" key={i}>
                          <div className="each-mypaths-name">
                            <Skeleton width={100} height={30} />
                          </div>
                          <div className="each-mypaths-desc">
                            <Skeleton width={"100%"} height={30} />
                          </div>
                        </div>
                      );
                    })
                : filteredPartnerPathData?.map((e, i) => {
                    return (
                      <div
                        className="each-mypaths-data"
                        key={i}
                        onClick={() => {
                          setPathActionEnabled(true);
                          setSelectedPathId(e?._id);
                          setSelectedPath(e);
                          // console.log(e, "selected path details");
                          viewPath(e?.nameOfPath);
                        }}
                      >
                        <div className="each-mypaths-name">{e?.nameOfPath}</div>
                        <div className="each-mypaths-desc">
                          {e?.description}
                        </div>
                      </div>
                    );
                  })}
            </div>
          </>
        ) : (
          <>
            <div className="mypathsNav">
              <div className="mypathsName">Name</div>
              <div className="mypathsCountry">Length</div>
              <div className="mypathsCountry">Cost Structure</div>
              <div className="mypathsMicrosteps">Services</div>
            </div>
            <div className="mypathsScroll-div">
              {loading
                ? Array(10)
                    .fill("")
                    ?.map((e, i) => {
                      return (
                        <div className="each-mypaths-data1" key={i}>
                          <div className="each-mypaths-detail">
                            <div className="each-mypathsName">
                              <Skeleton width={100} height={30} />
                            </div>
                            <div className="each-mypathsCountry">
                              <Skeleton width={100} height={30} />
                            </div>
                            <div className="each-mypathsCountry">
                              <Skeleton width={100} height={30} />
                            </div>
                            <div className="each-mypathsMicrosteps">
                              <Skeleton width={100} height={30} />
                            </div>
                          </div>
                          <div className="each-mypaths-desc">
                            <div className="each-mypaths-desc-txt">
                              <Skeleton width={100} height={30} />
                            </div>
                            <div className="each-mypaths-desc-txt1">
                              <Skeleton width={"100%"} height={30} />
                            </div>
                          </div>
                        </div>
                      );
                    })
                : filteredPartnerStepsData?.map((e, i) => {
                    return (
                      <div
                        className="each-mypaths-data1"
                        key={i}
                        onClick={() => {
                          setSelectedStepId(e?._id);
                          setStepActionEnabled(true);
                        }}
                      >
                        <div className="each-mypaths-detail">
                          <div className="each-mypathsName">
                            <div>
                              <div>{e?.name}</div>
                              <div
                                style={{
                                  fontSize: "0.8rem",
                                  fontWeight: "300",
                                }}
                              >
                                {e?._id}
                              </div>
                            </div>
                          </div>
                          <div className="each-mypathsCountry">
                            {e?.length ? e?.length : 0} Days
                          </div>
                          <div className="each-mypathsCountry">{e?.cost}</div>
                          <div className="each-mypathsMicrosteps">
                            {e?.other_data
                              ? Object.keys(e.other_data).length
                              : 0}
                          </div>
                        </div>
                        <div className="each-mypaths-desc">
                          <div className="each-mypaths-desc-txt">
                            Description
                          </div>
                          <div className="each-mypaths-desc-txt1">
                            {e?.description}
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </>
        )}

        {pathActionEnabled && (
          <div className="acc-popular1">
            <div
              className="acc-popular-top1"
              style={{
                display:
                  pathActionStep === 3
                    ? "none"
                    : metaDataStep === "success"
                    ? "none"
                    : "",
              }}
            >
              <div className="acc-popular-head1">
                {pathActionStep > 3 ? "Edit Paths" : "My Path Actions"}
              </div>
              <div
                className="acc-popular-img-box1"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  resetPathAction();
                }}
              >
                <img className="acc-popular-img1" src={closepop} alt="" />
              </div>
            </div>
            {pathActionStep === 1 && (
              <div className="acc-mt-div">
                <div className="acc-scroll-div">
                  <div
                    className="acc-step-box4"
                    onClick={() => {
                      setPathActionStep(4);
                    }}
                  >
                    Edit path
                  </div>
                  <div
                    className="acc-step-box4"
                    onClick={() => {
                      setPathActionStep(2);
                    }}
                  >
                    Delete path
                  </div>
                  <div
                    className="acc-step-box4"
                    onClick={() => {
                      setViewPathEnabled(true);
                      setPathActionEnabled(false);
                    }}
                  >
                    View path
                  </div>
                </div>
              </div>
            )}

            {pathActionStep === 2 && (
              <div className="acc-mt-div">
                <div className="acc-scroll-div">
                  <div
                    className="acc-step-box4"
                    onClick={() => {
                      deletePath();
                    }}
                  >
                    Confirm and delete
                  </div>
                </div>
                <div
                  className="goBack3"
                  onClick={() => {
                    setPathActionStep(1);
                  }}
                >
                  Go Back
                </div>
              </div>
            )}

            {actionLoading ? (
              <div className="popularlogo">
                <img className="popularlogoimg" src={lg1} alt="" />
              </div>
            ) : (
              ""
            )}

            {pathActionStep === 3 && (
              <div className="success-box2">Path Successfully Deleted</div>
            )}

            {pathActionStep === 4 &&
              (editPaths === "default" ? (
                <div className="acc-mt-div">
                  <div className="acc-sub-text">
                    What type of data do you want to edit?
                  </div>
                  <div className="acc-scroll-div">
                    <div
                      className="acc-step-box4"
                      onClick={() => {
                        setEditPaths("Edit meta data");
                      }}
                    >
                      Edit meta data
                    </div>
                    <div
                      className="acc-step-box4"
                      onClick={() => {
                        setEditPaths("Edit steps");
                      }}
                    >
                      Edit steps
                    </div>
                    <div
                      className="acc-step-box4"
                      onClick={() => {
                        setEditPaths("Edit who qualifies");
                      }}
                    >
                      Edit who qualifies
                    </div>
                  </div>
                  <div
                    className="goBack3"
                    onClick={() => {
                      setPathActionStep(1);
                    }}
                  >
                    Go Back
                  </div>
                </div>
              ) : editPaths === "Edit meta data" ? (
                metaDataStep === "default" ? (
                  <div className="acc-mt-div">
                    <div className="acc-sub-text">
                      Which meta data do you want to edit?
                    </div>
                    <div className="acc-scroll-div">
                      <div
                        className="acc-step-box4"
                        onClick={() => {
                          setMetaDataStep("nameOfPath");
                        }}
                      >
                        Name
                      </div>
                      <div
                        className="acc-step-box4"
                        onClick={() => {
                          setMetaDataStep("length");
                        }}
                      >
                        Length
                      </div>
                      <div
                        className="acc-step-box4"
                        onClick={() => {
                          setMetaDataStep("description");
                        }}
                      >
                        Description
                      </div>
                      <div
                        className="acc-step-box4"
                        onClick={() => {
                          setMetaDataStep("path_type");
                        }}
                      >
                        Path type
                      </div>
                      <div
                        className="acc-step-box4"
                        onClick={() => {
                          setMetaDataStep("destination_institution");
                        }}
                      >
                        Destination institution
                      </div>
                      <div
                        className="acc-step-box4"
                        onClick={() => {
                          setMetaDataStep("program");
                        }}
                      >
                        Program
                      </div>
                      <div
                        className="acc-step-box4"
                        onClick={() => {
                          setMetaDataStep("city");
                        }}
                      >
                        City
                      </div>
                      <div
                        className="acc-step-box4"
                        onClick={() => {
                          setMetaDataStep("country");
                        }}
                      >
                        Country
                      </div>
                    </div>
                    <div
                      className="goBack3"
                      onClick={() => {
                        setEditPaths("default");
                      }}
                    >
                      Go Back
                    </div>
                  </div>
                ) : metaDataStep === "success" ? (
                  <div className="success-box2">
                    You have successfully updated the{" "}
                    {metaDataStep === "nameOfPath"
                      ? "name"
                      : metaDataStep === "path_type"
                      ? "path type"
                      : metaDataStep === "destination_institution"
                      ? "destination institution"
                      : metaDataStep}{" "}
                    for this page. You will automatically be redirected to the
                    updated path page.
                  </div>
                ) : (
                  <>
                    <div className="acc-mt-div">
                      <div className="acc-scroll-div">
                        <div className="acc-sub-textt">
                          Current{" "}
                          {metaDataStep === "nameOfPath"
                            ? "name"
                            : metaDataStep === "path_type"
                            ? "path type"
                            : metaDataStep === "destination_institution"
                            ? "destination institution"
                            : metaDataStep}
                        </div>
                        <div className="acc-step-box5">
                          {selectedPath?.[metaDataStep] || ""}
                        </div>
                        <div className="acc-sub-textt">
                          New{" "}
                          {metaDataStep === "nameOfPath"
                            ? "name"
                            : metaDataStep === "path_type"
                            ? "path type"
                            : metaDataStep === "destination_institution"
                            ? "destination institution"
                            : metaDataStep}
                        </div>
                        <div className="acc-step-box6">
                          <input
                            type="text"
                            placeholder={`Enter ${
                              metaDataStep === "nameOfPath"
                                ? "name"
                                : metaDataStep === "path_type"
                                ? "path type"
                                : metaDataStep === "destination_institution"
                                ? "destination institution"
                                : metaDataStep
                            }`}
                            onChange={(e) => {
                              setNewValue(e.target.value);
                            }}
                            value={newValue}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          opacity: newValue?.length > 1 ? "1" : "0.5",
                          cursor:
                            newValue?.length > 1 ? "pointer" : "not-allowed",
                        }}
                        className="save-Btn"
                        onClick={() => {
                          if (newValue?.length > 1) {
                            editMetaData(metaDataStep);
                          }
                        }}
                      >
                        Save Changes
                      </div>
                      <div
                        className="goBack3"
                        onClick={() => {
                          setMetaDataStep("default");
                        }}
                      >
                        Go Back
                      </div>
                    </div>
                    {actionLoading ? (
                      <div className="popularlogo">
                        <img className="popularlogoimg" src={lg1} alt="" />
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )
              ) : editPaths === "Edit steps" ? (
                <div className="acc-mt-div">
                  <div className="acc-sub-text">
                    How do you want to edit the steps in this path?
                  </div>
                  <div className="acc-scroll-div">
                    <div className="acc-step-box4">Add new step</div>
                    <div className="acc-step-box4">Remove existing step</div>
                    <div className="acc-step-box4">
                      Edit backup path for existing step
                    </div>
                    <div className="acc-step-box">Reorder existing steps</div>
                  </div>
                  <div
                    className="goBack3"
                    onClick={() => {
                      setEditPaths("default");
                    }}
                  >
                    Go Back
                  </div>
                </div>
              ) : editPaths === "Edit who qualifies" ? (
                <div className="acc-mt-div">
                  <div className="acc-sub-text">
                    Which of the current coordinates do you want to edit?
                  </div>
                  <div className="acc-scroll-div">
                    <div className="acc-step-box4">Grade</div>
                    <div className="acc-step-box4">Grade point avg</div>
                    <div className="acc-step-box4">Curriculum</div>
                    <div className="acc-step-box4">Stream</div>
                    <div className="acc-step-box4">Financial situation</div>
                    <div className="acc-step-box4">Personality</div>
                  </div>
                  <div
                    className="goBack3"
                    onClick={() => {
                      setEditPaths("default");
                    }}
                  >
                    Go Back
                  </div>
                </div>
              ) : (
                ""
              ))}
          </div>
        )}

        {stepActionEnabled && (
          <div className="acc-popular">
            <div
              className="acc-popular-top"
              style={{ display: stepActionStep === 3 ? "none" : "" }}
            >
              <div className="acc-popular-head">My Step Actions</div>
              <div
                className="acc-popular-img-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setStepActionEnabled(false);
                  setStepActionStep(1);
                  setSelectedStepId("");
                }}
              >
                <img className="acc-popular-img" src={closepop} alt="" />
              </div>
            </div>
            {stepActionStep === 1 && (
              <div style={{ marginTop: "3rem" }}>
                <div className="acc-step-box">Edit step</div>
                <div
                  className="acc-step-box"
                  onClick={() => {
                    setStepActionStep(2);
                  }}
                >
                  Delete step
                </div>
              </div>
            )}

            {stepActionStep === 2 && (
              <div style={{ marginTop: "3rem" }}>
                <div
                  className="acc-step-box"
                  onClick={() => {
                    deleteStep();
                  }}
                >
                  Confirm and delete
                </div>
                <div
                  className="goBack2"
                  onClick={() => {
                    setStepActionStep(1);
                  }}
                >
                  Go Back
                </div>
              </div>
            )}

            {actionLoading ? (
              <div className="popularlogo">
                <img className="popularlogoimg" src={lg1} alt="" />
              </div>
            ) : (
              ""
            )}

            {stepActionStep === 3 && (
              <div className="success-box1">Step Successfully Deleted</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyPaths;
