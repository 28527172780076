import React, { useEffect, useState } from "react";
import close from "../../images/close.svg";
import axios from "axios";

const LevelThree = ({
  profileData,
  createLevelThree,
  setCreateLevelThree,
  handleProfileData,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingSaveAnswer, setLoadingSaveAnswer] = useState(false);
  const [allQuestions, setAllQuestions] = useState([]);
  const [questionNo, setQuestionNo] = useState(0);
  const [answered, setAnswered] = useState(false);
  const [allAnsweredQuestions, setAllAnsweredQuestions] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const answrOptions = [
    "Dislike",
    "Slightly Dislike",
    "Neutral",
    "Slightly Enjoy",
    "Enjoy",
  ];
  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://careers.marketsverse.com/question/get`)
      .then(({ data }) => {
        const initialData = data.data;
        const sorted = initialData
          .slice()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setAllQuestions(sorted);
        setLoading(false);
      });
    axios
      .get(
        `https://careers.marketsverse.com/userAnswers/get?userId=${profileData?._id}`
      )
      .then(({ data }) => {
        if (data.status && data.total < 48) {
          setAllAnsweredQuestions(data.data);
        }
      });
  }, []);

  const checkQuestionStatus = (question) => {
    setAnswered(false);
    setSelectedAnswer("");
    const found = allAnsweredQuestions.find((o) => o.question === question);
    // console.log(found, "lwheflwkheflwhelfhwf");
    if (found) {
      setAnswered(true);
      setSelectedAnswer(found.answer);
    }
    // axios
    //   .get(
    //     `https://careers.marketsverse.com/userAnswers/get?userId=${id}&question=${question}`
    //   )
    //   .then(({ data }) => {
    //     if (data.status) {
    //       setAnswered(true);
    //       setSelectedAnswer(data.data[0].answer);
    //     }
    //   });
  };

  useEffect(() => {
    if (allQuestions.length > 0) {
      checkQuestionStatus(allQuestions[questionNo].question);
    }
  }, [questionNo, allQuestions]);

  const addPersonality = () => {
    axios
      .put(`https://careers.marketsverse.com/users/addPersonality`, {
        userId: profileData?._id,
      })
      .then(({ data }) => {
        if (data.status) {
          handleProfileData();
          setCreateLevelThree(false);
        }
      });
  };

  const saveAnswer = () => {
    setLoadingSaveAnswer(true);
    axios
      .post(`https://careers.marketsverse.com/userAnswers/add`, {
        userId: profileData?._id,
        question: allQuestions[questionNo].question,
        relatedTo: allQuestions[questionNo].relatedTo,
        answer: selectedAnswer,
      })
      .then(({ data }) => {
        if (data.status) {
          if (questionNo !== 47) {
            setQuestionNo(questionNo + 1);
            setLoadingSaveAnswer(false);
          } else {
            addPersonality();
          }
        }
      });
  };

  const nextQuestion = () => {
    console.log(answered, "lkwehlwkehflw");

    if (!answered && selectedAnswer) {
      saveAnswer();
    } else {
      setQuestionNo(questionNo + 1);
    }
    //   setQuestionNo(questionNo + 1);
  };

  return (
    <>
      <div className="popularS">
        {/* {levelTwoStep === 7 && (
          <div className="successMsg">
            You Have Successfully Created Your Legally Profile Level 2.
          </div>
        )} */}
        <div
          className="head-txt"
          style={{
            height: "4rem",
            // display: levelTwoStep === 7 ? "none" : "",
          }}
        >
          <div>Legally Profile Level Three</div>
          <div
            onClick={() => {
              setCreateLevelThree(false);
              //   setLevelTwoFields({
              //     financialSituation: "",
              //     school: "",
              //     performance: "",
              //     curriculum: "",
              //     stream: "",
              //     grade: "",
              //     linkedin: "",
              //   });
            }}
            className="close-div"
          >
            <img src={close} alt="" />
          </div>
        </div>
        <div
          className="overall-div"
          style={{
            height: "calc(100% - 4rem)",
            // display: levelThreeStep === 7 ? "none" : "",
          }}
        >
          <>
            <div
              style={{
                marginBottom: "2rem",
                fontSize: "1rem",
                marginTop: "2rem",
              }}
            >
              {allQuestions[questionNo]?.question}
            </div>
            <div className="btnss-div">
              {!loading &&
                answrOptions.map((item) => (
                  <div
                    className={
                      item === selectedAnswer
                        ? "eachh-btnn-selected"
                        : "eachh-btnn"
                    }
                    onClick={() => {
                      setSelectedAnswer(item);
                    }}
                  >
                    {item}
                  </div>
                ))}
            </div>
            <div className="stepBtns1">
              <p style={{ textAlign: "center" }}>{`${questionNo + 1} / 48`}</p>
              <div
                onClick={(e) => {
                  if (questionNo !== 0) {
                    setQuestionNo(questionNo - 1);
                  }
                }}
                style={{
                  background: "#1F304F",
                  width: "100%",
                  height: "3.5rem",
                  opacity: questionNo === 0 ? "0.5" : 1,
                  //   cursor: "not-allowed",
                }}
              >
                Go Back
              </div>
              <div
                style={{
                  height: "3.5rem",
                  background: "#334480",
                  width: "100%",
                  //   cursor: levelTwoFields?.financialSituation
                  //     ? "pointer"
                  //     : "not-allowed",
                  opacity: selectedAnswer ? "1" : "0.5",
                }}
                onClick={() => {
                  if (!loadingSaveAnswer && selectedAnswer) {
                    nextQuestion();
                  }
                }}
              >
                {loadingSaveAnswer ? "Saving Answer.." : "Next Question"}
              </div>
            </div>
          </>

          {/* 
          {levelTwoLoading && (
            <div
              className="loading-component"
              style={{
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                position: "absolute",
                display: "flex",
              }}
            >
              <LoadingAnimation1 icon={lg1} width={200} />
            </div>
          )}  */}
        </div>
      </div>
    </>
  );
};

export default LevelThree;
